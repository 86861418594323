"use client";
import { useZodForm } from "ui/use-zod-form";
import { resolver as formResolver } from "api/form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "ui/form";
import { Input } from "ui/input";
import z from "zod";
import { Button } from "ui/button";
import { useState } from "react";
import { Icon } from "ui/icon";
import { Alert, AlertDescription } from "ui/alert";

const FORM_ID = 123;

const schema = z.object({
  email: z
    .string({
      required_error: "Vul een geldig e-mailadres in.",
      invalid_type_error: "Vul een geldig e-mailadres in.",
    })
    .email("Vul een geldig e-mailadres in."),
});

type FormValues = z.infer<typeof schema>;

interface NewsletterFormProps {
  email?: string;
}

const NewsletterForm = ({ email }: NewsletterFormProps) => {
  const form = useZodForm(schema, { defaultValues: { email } });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data: FormValues) => {
    const res = await formResolver.submit(FORM_ID, data);
    if (!res) return;

    if (res.errors) {
      Object.keys(res.errors).forEach((error) =>
        form.setError(error, { message: res.errors![error]![0] })
      );
    } else {
      setSuccess(true);
    }
  };

  if (success)
    return (
      <Alert variant="success">
        <AlertDescription>Bedankt voor uw inschrijving.</AlertDescription>
      </Alert>
    );

  return (
    <Form {...form}>
      <form
        className="flex items-end gap-2.5 lg:items-center lg:justify-center"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="lg:flex-row lg:flex lg:space-y-0 lg:items-center lg:gap-2.5">
              <FormLabel className="whitespace-nowrap font-bree text-base font-semibold lg:mb-0">
                Schrijf je in voor onze nieuwsbrief
              </FormLabel>
              <FormControl className="lg:w-[290px]">
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          className="h-[44px] w-[44px] lg:h-auto lg:w-auto"
          aria-label="submit newsletter subscription"
        >
          <span className="hidden lg:inline-block">Aanmelden</span>
          <Icon icon="chevronBoldRight" size={16} />
        </Button>
      </form>
    </Form>
  );
};

export { NewsletterForm };
